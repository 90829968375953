import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import interceptorSetup from './router/interceptors'
import 'es6-promise/auto';
import i18n from './locales/index'

import VueBus from 'vue-bus';
 
Vue.use(VueBus);


// Require the polyfill before requiring any other modules.
require('intersection-observer');

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.baseURL = "https://umsbackend.roba-one.de/api/v1/"
//  axios.defaults.baseURL = "http://192.168.10.119:8000/api/v1/"
// axios.defaults.baseURL = "http://localhost:8000/api/v1/"
axios.defaults.baseURL = "https://db.umsjourfixe.de/api/v1/"


//initialising axios interceptors for refresh token
interceptorSetup()

Vue.config.productionTip = false
Vue.use(require ('vue-moment'));
Vue.use(require('moment/locale/de'))


new Vue({
  i18n,
  store,
  router,
  vuetify,
  data: () => ({
    alignments: [
      'start',
      'center',
      'end',
    ],
  }),
  render: h => h(App)
}).$mount('#app')

// add_header Access-Control-Allow-Origin *;
//add_header 'Access-Control-Allow-Headers' 'DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range';